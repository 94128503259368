<template>
  <div class="AddTreatment">
    <div class="vx-row">
      <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px"
      >
      </feather-icon>
    </div>

    <div class="p-5">
      <div style="font-weight: bold">{{ $t("ChooseMainSpecialty") }}</div>

      <div class="flex flex-col m-auto" v-if="surgeryCategories.length > 0">
        <div class="flex overflow-x-scroll py-10 items hide-scroll-bar">
          <div
            class="inline-block px-1"
            v-on:click="
              selectSurgCategory(null, null);
              isAll = true;
            "
          >
            <div
              style="background-color: #355a85"
              :class="[{ 'bg-primary': ActiveIndex == null }]"
              class="RoundedCurve w-48 h-32 max-w-xs overflow-hidden"
            >
              <h4
                class="mx-auto text-center mt-10 w-36 justify-center text-white"
              >
                {{ $t("All") }}
              </h4>
            </div>
          </div>
          <div
            class="flex flex-nowrap lg:ml-40 md:ml-20 ml-10"
            v-for="(item, index) in surgeryCategories"
            :key="index"
          >
            <div
              class="inline-block px-1"
              v-on:click="
                selectSurgCategory(item, index);
                selectedSpecialityFilter = item.Specialty;
              "
            >
              <div
                :class="[{ 'bg-primary': ActiveIndex == index }]"
                style="background-color: #355a85"
                class="RoundedCurve w-48 h-32 max-w-xs overflow-hidden"
              >
                <h4
                  class="
                    mx-auto
                    text-center
                    mt-10
                    w-36
                    justify-center
                    text-white
                  "
                >
                  {{ item.Specialty.Name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="font-weight: bold" class="mt-2">
        {{ $t("ChooseSubSpecialty") }}
      </div>

      <div class="flex flex-col m-auto" v-if="surgeriesByCat.length > 0">
        <div class="flex overflow-x-scroll py-10 hide-scroll-bar">
          <div
            class="flex flex-nowrap lg:ml-40 md:ml-20 ml-10"
            v-for="(item, index) in surgeriesByCat"
            :key="index"
          >
            <div
              class="inline-block px-1"
              v-on:click="
                selectedSpecialityFilter = item.Specialty;
                MakeActive(index);
              "
            >
              <div
                :class="[{ 'bg-primary': SecondActiveIndex == index }]"
                style="background-color: #355a85"
                class="RoundedCurve w-48 h-32 max-w-xs overflow-hidden"
              >
                <h4
                  class="
                    mx-auto
                    text-center
                    mt-10
                    w-36
                    justify-center
                    text-white
                  "
                >
                  {{ item.Specialty.Name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mt-2">{{ $t("AddTreatments") }}</h2>
    <div class="vx-row mt-5" id="customRow">
      <vs-card
        id="custumcard"
        class="vx-col lg:w-1/3 ml-2 mr-2"
        style="height: 40vh; overflow-y: scroll"
      >
        <h3 class="ActiveCardHeader primaryBackColor">
          {{ $t("Treatments") }}
        </h3>
        <div class="m-5">
          <div class="primarycolor mt-1 vx-row">
            <feather-icon
              style="width: 15px"
              icon="PlusCircleIcon"
              class="cursor-pointer ml-3 mr-3"
            >
            </feather-icon>
            <u @click="AddNewTreatement()" class="mt-1">{{
              $t("CreateNewTreatment")
            }}</u>
          </div>

          <vs-input
            v-model="search.treatmentName"
            :placeholder="$t('Search')"
            class="w-full"
            name="search"
          />
          <div
            v-for="item in filterSurgery"
            :key="item.ID"
            :class="item.IsSelected ? 'Active' : 'InActive'"
            class="shadow-md w-full mt-2"
          >
            <p class="m-5 p-3 vx-row">
              <vs-checkbox
                v-model="item.IsSelected"
                @input="SurgeryChecked(item)"
              ></vs-checkbox>
              {{ item.Name }}
            </p>
          </div>
        </div>
      </vs-card>

      <vx-card
        class="vx-col lg:w-1/3 ml-2 mr-2"
        style="height: 40vh; overflow-y: scroll"
      >
        <h3 class="primarycolor">{{ $t("AllAddedTreatments") }}</h3>
        <div class="m-5">
          <point
            v-for="(item, index) in hospitalSurgery"
            @editTreatment="editTreatment(item)"
            :data="item.Surgery"
            :key="index"
            :hameEdit="true"
          />
        </div>
      </vx-card>
    </div>

    <div class="w-full m-10">
      <div class="text-right" style="color: #004477">
        <u @click="SaveTreatments()" style="font-size: 20px">{{
          $t("Save")
        }}</u>
      </div>
    </div>
  </div>
</template>

<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import SurgeryModule from "@/store/settings/surgery/moduleSurgery.js";
import point from "@/components/point.vue";

export default {
  components: { point },
  data() {
    return {
      selectedSpecialityFilter: {},
      search: { treatmentName: "" },
      hospitalSurgery: [],
      isAll: false,
      ActiveIndex: null,
      SecondActiveIndex: null,
      MainsurgeriesByCat: [],
      surgeriesByCat: [],
      surgeryCategories: [],
    };
  },
  methods: {
    editTreatment(surgery) {
      if (this.hospitalSurgery.findIndex((obj) => !obj.ID) == -1)
        this.$router.push("/hospital/Add-EditTreatmentSurgery/" + surgery.ID);
      else
        this.$vs.notify({
          title: this.$t("Info"),
          text: this.$t("editTreatmentSurgery"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
    },
    back() {
      this.$router.go(-1);
    },

    MakeActive(index) {
      this.SecondActiveIndex = index;
    },
    selectSurgCategory(data, index) {
      this.ActiveIndex = index;
      this.SecondActiveIndex = null;
      if (data != null) {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID == data.Specialty.ID
        );
        this.isAll = false;
      } else {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
      }
    },
    SurgeryChecked(surgery) {
      if (surgery.IsSelected) {
        this.hospitalSurgery.push({ SurgeryID: surgery.ID, Surgery: surgery });
      } else {
        var index = this.hospitalSurgery.findIndex(
          (obj) => obj.Surgery.ID == surgery.ID
        );
        this.hospitalSurgery.splice(index, 1);
      }
    },
    SaveTreatments() {
      var vm = this;
      this.$vs.loading();
      this.hospitalSurgery.forEach(
        (obj) => (obj.HospitalID = vm.$store.state.AppActiveUser.Hospital.ID)
      );
      this.$store
        .dispatch("HospitalList/SaveHospitalSurgeries", this.hospitalSurgery)
        .then((res) => {
          this.$vs.loading.close();
          this.getHospitalSurgeries();
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    AddNewTreatement() {
      this.$router.push("/hospital/Add-EditTreatmentSurgery");
    },
    searchHospitalSpecialty() {
      debugger;
      this.$store
        .dispatch("HospitalList/SearchHospitalSpecialtys", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
        })
        .then((res) => {
          this.surgeryCategories = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID == null
          );
          this.MainsurgeriesByCat = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID != null
          );
          this.surgeriesByCat = this.MainsurgeriesByCat;
        });
    },
    setcheckedItems() {
      if (this.hospitalSurgery && this.allSurgeries)
        this.allSurgeries.forEach((element) => {
          if (
            this.hospitalSurgery.findIndex(
              (obj) => obj.SurgeryID == element.ID
            ) != -1
          )
            element.IsSelected = true;
        });
    },
    getHospitalSurgeries() {
      this.$store
        .dispatch("HospitalList/SearchHospitalSurgies", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
        })
        .then((res) => {
          this.hospitalSurgery = res.data.Data;
          this.setcheckedItems();
        });
    },
  },
  computed: {
    allSurgeries() {
      return this.$store.state.SurgeryList.surgeries;
    },
    filterSurgery() {
      debugger;
       debugger;
      var filtersurgeries = [];

      if (this.isAll == true) filtersurgeries = this.allSurgeries;
      filtersurgeries = this.allSurgeries;
      if (this.search.treatmentName && this.search.treatmentName != "") {
        debugger;
        filtersurgeries = filtersurgeries.filter((obj) =>
          obj.Name != null && obj.Name.includes(this.search.treatmentName)
        );
        debugger;
      }
      debugger;

      if (this.selectedSpecialityFilter.ID) {
        filtersurgeries = filtersurgeries.filter(
          (obj) => obj.SpecialtyID == this.selectedSpecialityFilter.ID
        );
      }
     
      debugger
      return filtersurgeries;
    },
    specialities() {
      return this.$store.state.HospitalList.hospitalSpecialties;
    },
  },
  created() {
    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
    if (!SurgeryModule.isRegistered) {
      this.$store.registerModule("SurgeryList", SurgeryModule);
      SurgeryModule.isRegistered = true;
    }

    this.getHospitalSurgeries();
    this.$store.dispatch("SurgeryList/GetAllSurgeries").then((res) => {
      this.setcheckedItems();
    });

    this.searchHospitalSpecialty();
    debugger;
  },
};
</script>
<style >
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
#custumcard .vs-card--content {
  padding: unset;
}
#customRow .vx-col {
  padding: unset;
}
</style>